.AuthorizationForm {
    height: 450px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    padding: 86px;
}
.AuthorizationForm__img {
    width: 204px;
    height: 72px;
    margin: 0 auto 3rem;
}
.AuthorizationForm__img img {
    width: 100%;
    height: 100%;
}
.AuthorizationForm__input {
    display: flex;
    align-items: center;
}
.AuthorizationForm__input,
.AuthorizationForm button,
.AuthorizationForm__form input[type=submit] {
    width: 100%;
    min-width: 360px;
    height: 3rem;   
    border: 1px solid #E8E8E8;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 1rem;
}
.AuthorizationForm__input div {
    margin-right: 26px;
}
.AuthorizationForm__input input {
    width: 100%;
    border: none;
    color: #98999A;
}
.AuthorizationForm__form input[type=submit] {
    font-size: 1rem;
    margin-top: 0.5rem;
    background: #70B2FF;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
}
.AuthorizationForm__form input[type=submit]:disabled { 
    background: #ccc; 
}
.AuthorizationForm__input.errorMessage {
    border-color: #FF3748;
}
.AuthorizationForm__errorMessage {
    color: #FF3748;
    font-size: 0.75rem;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .AuthorizationForm__input,
    .AuthorizationForm__form input[type=submit] {
        min-width: 100%;
    }
}
