.AdminCard {
    position: fixed;
    top: 4%;
    left: 2%;
    z-index: 10;
    width: 330px;
    max-height: 4rem;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.AdminCard__name {
    display: flex;
    align-items: center;
}
.AdminCard__icon {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: #70B2FF;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.75rem;
}
.AdminCard button {
    color: #70B2FF;
    font-size: 0.75rem;
    border: none;
    background: none;
    cursor: pointer;
}