.CustomPopup {
    padding: 1rem;
    width: 535px;
    height: auto;  
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
.CustomPopup__content {
    display: flex;
}
.CustomPopup__avatar {
    min-width: 100px;
    height: 100px;
    margin-right: 24px;
}
.CustomPopup__avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.CustomPopup__title {
    color: #70B2FF;
}
.CustomPopup__subtitle {
    margin-bottom: 4px;
}
.CustomPopup__description_item p:first-child {
    color: #999999;
    margin-right: 4px;
    float: left;
}

