
/** Roboto **/

@font-face {
	font-family: 'Roboto';
	src: url('./fonts/Roboto/Roboto-Regular.eot');
	src: local('☺'), url('./fonts/Roboto/Roboto-Regular.woff') format('woff'), url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype'), url('./fonts/Roboto/Roboto-Regular.svg') format('svg');
	font-weight: 400;
	font-style: normal;
}


body, input {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #444444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*, ::before, ::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
input:focus-visible {
  outline: none;
}
.mapboxgl-popup-tip {
  display: none!important;
}
.mapboxgl-popup-content {
  background: none!important;
  box-shadow: none!important;
  border-radius: 0!important;
  padding: 0!important;
}